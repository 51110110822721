import React from "react";
import { Link, graphql } from "gatsby";
import { Container, Row, Col, Card } from "react-bootstrap";
import SEO from "../components/seo";
import ReactMarkdown from "react-markdown/with-html";
import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRoll";
import ContactForm from "../components/forms/contact";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

export const WhoWeArePageTemplate = ({ title, titleSEO, imageContainer, content, philosophy }) => (
  <>
    <SEO title={titleSEO} />
    <Container className="d-flex flex-column justify-content-center align-items-center">
      <Row className="text-center my-5 pt-3 pb-5">
        <h1 className="block-invert">{title}</h1>
      </Row>
      <Row>
        <Col xs={12} md={8}>
          <p className="pr-0 pr-md-5 mb-5 corner-block">
            <ReactMarkdown source={content} escapeHtml={false} linkTarget="_blank" />
          </p>
          <blockquote>
            <p>
              Anyone involved in or with the glass industry can’t argue... everyday is an adventure, get to know us...
              we do things differently.
            </p>
          </blockquote>
          <p>
            <i>Jacques,</i> <br />
            <i>Adventurer</i> <br />
            frogitout.com
          </p>
        </Col>
        <Col xs={12} md={4} className="text-center">
          <PreviewCompatibleImage
            imageInfo={{
              image: imageContainer.image,
              alt: imageContainer.alt,
              style: { border: "solid rgb(117, 117, 117) 2px" },
            }}
          />
          <small className="mt-3">{imageContainer.alt}</small>
        </Col>
      </Row>
    </Container>

    <Container className="d-flex flex-column justify-content-center align-items-center">
      <Row className="text-center mt-5 pt-3">
        <h1 className="block-invert">{philosophy.title}</h1>
      </Row>
      <Row className=" mb-5 py-5">
        <Col xs={12} md={8} className=" mt-5 mx-auto text-center text-lg-left">
          <p className="pr-0 pr-md-5 text-justify">
            {/* <ReactMarkdown source={philosophy.content} escapeHtml={false} linkTarget="_blank" /> */}
            Frogitout only supports modern tools, equipment & methods of part removal and replacement.
          </p>
        </Col>
        <Col xs={12} md={8} className=" mt-5 mx-auto text-center text-lg-left">
          <p className="pr-0 pr-md-5 text-justify">
            {/* <ReactMarkdown source={philosophy.content} escapeHtml={false} linkTarget="_blank" /> */}
            To put it into perspective, you wouldn't send Patrick Mahomes on the field with an old-timey leather helmet.
            Even though tools & equipment are given a new flashy name and a coat of paint, they are still tools and
            techniques from the 70’s & 90’s.
          </p>
        </Col>
        <Col xs={12} md={8} className=" mx-auto">
          <Row className="text-center">
            <Col xs={6} className="mt-5 mx-auto text-center">
              <PreviewCompatibleImage
                imageInfo={{
                  image: philosophy.imageContainer1.image,
                  alt: philosophy.imageContainer1.alt,
                  style: {
                    border: "solid white 2px",
                    maxWidth: "300px",
                    maxHeight: "200px",
                    margin: "auto",
                  },
                }}
              />
              <small className="mt-3">{philosophy.imageContainer1.alt}</small>
            </Col>
            <Col xs={6} className=" mt-5 mx-auto text-center">
              <PreviewCompatibleImage
                imageInfo={{
                  image: philosophy.imageContainer2.image,
                  alt: philosophy.imageContainer2.alt,
                  style: {
                    height: "auto",
                    maxHeight: "200px",
                    maxWidth: "300px",
                    margin: "auto",
                    objectFit: "contain",
                  },
                }}
              />
              <small className="mt-3">{philosophy.imageContainer2.alt}</small>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={8} className=" mt-5 mx-auto">
          <blockquote style={{ marginRight: 0 }}>
            <p>
              A skilled technician is any glass companies greatest asset….take care of them with modern equipment and
              training.
            </p>
          </blockquote>
        </Col>
      </Row>
    </Container>
  </>
);

const WhoWeArePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <WhoWeArePageTemplate
        title={frontmatter.title}
        titleSEO={frontmatter.titleSEO}
        imageContainer={frontmatter.imageContainer}
        content={frontmatter.content}
        philosophy={frontmatter.philosophy}
      />
    </Layout>
  );
};

export default WhoWeArePage;

export const pageQuery = graphql`
  query WhoWeArePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "who-we-are-page" } }) {
      frontmatter {
        title
        titleSEO
        imageContainer {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        content
        philosophy {
          title
          titleSEO
          imageContainer1 {
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          imageContainer2 {
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          content
        }
      }
    }
  }
`;
